import React, { useState } from "react";
import "assets/styles/pages/sidebar.scss";
import "assets/styles/pages/dashboardstatus.scss";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import editiconpencil from "assets/images/admin/edit-icon.svg";
import upload from "assets/images/admin/upload.svg";
import plantedOn from "assets/images/admin/plantedOn.svg";
import attach from "assets/images/admin/attach.svg";
import eyeicon from "assets/images/admin/visibility.png";
import "react-pro-sidebar/dist/css/styles.css";
import "assets/styles/pages/customheader.scss";
import AdminService from "services/admin/admin.service";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import ProjectViewthubnailslider from "../project-thumbnail-slider";
import Pagination from "react-js-pagination";

import noimage from "assets/images/noImage.png";
import { doAdminLogout } from "../login";
import { isMobile } from "react-device-detect";

export default function Greenassetsstatuspage() {
  const adminApi = new AdminService();
  const [greenAssetList, setGreenAssetList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [trackId, setTrackId] = useState("");
  const [trackDelete, setTrackDelete] = useState(false);

  const trackClose = () => {
    setTrackDelete(false);
    setTrackId("");
  };

  const validationMessage = (data) => {
    if (data.response.status === 401) {
      toast.error("Authentication failed");
      setTimeout(() => {
        doAdminLogout();
        navigate("/admin/");
      }, 3000);
    } else if (data.response.status === 403) {
      toast.error("Un Authorized user");
      setTimeout(() => {
        doAdminLogout();
        navigate("/admin/");
      }, 3000);
    } else {
      toast.error(data.response.data.message);
    }
  };

  const listPlanters = async () => {
    let inputs = {
      pageNumber: 0,
      pageSize: perPage,
      greenAssetListFilter: {
        attributeMap: {
          city: "",
          state: "",
        },
        speciesId: 0,
      },
      sortingFieldName: 0,
      sortingOrder: 0,
    };
    setLoading(true);
    await adminApi
      .listGreenAssets(inputs)
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          setActivePage(1);
          setGreenAssetList(data.data);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          validationMessage(error);
        }
      });
  };

  const deleteProject = async () => {
    setLoading(true);
    await adminApi
      .deleteProjects(trackId)
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          toast.success("Project Deleted Successfully");
          setTrackDelete(false);
          listPlanters();
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            toast.error("JWT Token has Expired");
            navigate("/admin/");
          } else {
            toast.error(error.response.data.message);
          }
        }
      });
  };
  const handlePageChange = async (pageNumber) => {
    await setActivePage(pageNumber);
    let inputs = {
      pageNumber: pageNumber - 1,
      pageSize: perPage,
      greenAssetListFilter: {
        attributeMap: {
          city: "",
          state: "",
        },
        speciesId: 0,
      },
      sortingFieldName: 0,
      sortingOrder: 0,
    };
    setLoading(true);
    await adminApi
      .listGreenAssets(inputs)
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          setGreenAssetList(data.data);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            toast.error("JWT Token has Expired");
            navigate("/admin");
          } else {
            toast.error(error.response.data.message);
          }
        }
      });
  };

  useEffect(() => {
    listPlanters();
  }, []);

  const viewGreenAssetPage = async (id) => {
    setLoading(true);
    await adminApi
      .getGreenAsset(id)
      .then((data) => {
        if (data?.status === 200) {
          setLoading(false);
          let path1 = "/admin/viewgreenasset";
          let pathSet = `?id=${id}`;
          navigate(path1 + pathSet);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          toast.error(error);
        }
      });
  };

  return (
    <>
      <div className="dashboard-status-wrapper">
        <div className="row">
          <div className="col-md-12">
            <h3 className="dark-theme-green margin-btm"> Green Assets</h3>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-3"></div>
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <Link
              to="/admin/uploadgreenasset"
              className="green-asset-btn green-asset-white-button fl-right"
            >
              {/* <img className='green-asset-img-fluid' src={upload} alt='uploadicon' /> */}
              <img
                className="green-asset-img-fluid"
                src={upload}
                alt="bellicon"
              />
              Upload
            </Link>
          </div>
        </div>

        <div className="row mg-top20">
          {greenAssetList.greenAsset &&
            greenAssetList.greenAsset.map((greenAssets) => {
              return (
                <div className="col-md-6" key={greenAssets.id}>
                  <div
                    className="dashboard-card-wrapper"
                    style={{ minHeight: "217px" }}
                  >
                    <div className="wid-40 padding-10">
                      {greenAssets.imageResource &&
                      greenAssets.imageResource ? (
                        <ProjectViewthubnailslider
                          images={greenAssets.imageResource.map((item) => {
                            return item.imageUrl;
                          })}
                          key={greenAssets.imageResource.id}
                        />
                      ) : (
                        <img src={noimage} alt="" />
                      )}
                    </div>
                    <div className="dashboard-card-body wid-60 padding10">
                      <div className="dis-title-status">
                        <h5 className="heading-text">{greenAssets.siteName}</h5>
                      </div>
                      {greenAssets.totalSpeciesCount > 0 ? (
                        <div>
                          <h6 style={{ color: "#097b44" }}>
                            {" "}
                            <img
                              src={plantedOn}
                              alt="treeRightIconGreen"
                              style={{ height: "25px" }}
                            />{" "}
                            {greenAssets.totalSpeciesCount} Trees
                          </h6>
                        </div>
                      ) : (
                        ""
                      )}
                      <p className="date-time-text">
                        {" "}
                        State : <b>{greenAssets.attributeMap.state} </b>
                      </p>
                      <p className="date-time-text">
                        {" "}
                        Village/City : <b>{greenAssets.attributeMap.city} </b>
                      </p>
                      <div
                        className="green-asset-btn-wrapper"
                        style={{
                          display: "flex",
                        }}
                      >
                        <div className="upload-kml-btn">
                          <span
                            className="gap-circle-icon"
                            onClick={() => {
                              const assetId = greenAssets?.id;
                              let path1 = "/admin/uploadGreenAssetKml";
                              let pathSet = `?id=${assetId}`;
                              navigate(path1 + pathSet);
                            }}
                          >
                            {" "}
                            <button
                              className="green-upload-view"
                              to="/admin/uploadgreenasset"
                              type="button"
                            >
                              {" "}
                              <img
                                className="green-asset-img-fluid"
                                src={attach}
                                alt="bellicon"
                              />
                              <span> Add KML</span>
                            </button>{" "}
                          </span>
                        </div>
                        <div
                          className={
                            isMobile
                              ? `item-direction-wr1`
                              : `item-direction-wr green-asset-right-alig-icon`
                          }
                        >
                          <span
                            className="gap-circle-icon"
                            onClick={() => {
                              const assetId = greenAssets.id;
                              let path1 = "/admin/uploadgreenasset";
                              let pathSet = `?id=${assetId}`;
                              navigate(path1 + pathSet);
                            }}
                          >
                            {" "}
                            <button
                              className="green-circle-view"
                              to="/admin/uploadgreenasset"
                              type="button"
                            >
                              {" "}
                              <img
                                className="green-asset-img-fluid-edit"
                                src={editiconpencil}
                                alt="bellicon"
                              />
                              <span> Edit </span>
                            </button>{" "}
                          </span>
                          <span className="gap-circle-icon">
                            {" "}
                            <button
                              className="green-circle-view"
                              to="/admin/viewgreenasset"
                              onClick={() => viewGreenAssetPage(greenAssets.id)}
                            >
                              {" "}
                              <img
                                className="green-asset-img-fluid"
                                src={eyeicon}
                                alt="bellicon"
                              />
                              <span style={{ marginRight: "5px" }}> View </span>
                            </button>{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        {loading === false && greenAssetList.totalNoOfRecords > 10 ? (
          <div>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={greenAssetList.totalNoOfRecords}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
        ) : null}
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <Modal show={trackDelete} centered>
        <Modal.Header closeButton onClick={trackClose}>
          <Modal.Title>Delete Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete ?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="whiteColor"
            style={{ color: "black" }}
            onClick={trackClose}
          >
            No
          </Button>
          <Button variant="primary" onClick={deleteProject}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}
